import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  status: "idle",
  userRegistered: null,
  amountPaid:null,
  userDetails:null,
  error: null,
  promoMessage:""
}
export const registerUser = createAsyncThunk(
  "register/registerUser",
  async (registrationFormData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/signup",
        registrationFormData
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    reset(state,action){
      state.error=null
      state.userRegistered=null
      state.amountPaid=null
      state.userDetails=null
      state.promoMessage=""
    }
  },
  extraReducers: {
    [registerUser.pending]: (state, action) => {
      state.status = "loading"
    },
    [registerUser.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.userRegistered = true;
      state.amountPaid = action.payload.amountPaid
      state.userDetails = action.payload.userDetails
      state.referredBy = action.payload.referredUser
      state.promoMessage = action.payload.promoMessage
      // if(action.payload.provider!=="email"){
      //   localStorage.setItem('inktoken', action.payload.jwttoken)
      // }
    },
    [registerUser.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.payload.message
    },
  },
})

export const { reset } = registerSlice.actions

export default registerSlice.reducer 