// src/Checkout.js
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  Divider,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  Table,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ProductCard from "./ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getallProducts, reset } from "../StateSlices/getproductsSlice";
import { getuserInfo } from "../StateSlices/getuserSlice";
import getCheckoutProductsSlice, {
  fetchProducts,
} from "../StateSlices/getCheckoutProductsSlice";
import swal from "sweetalert";
import {
  deleteCheckoutItem,
  resetCheckoutDelete,
} from "../StateSlices/deleteCheckoutItemSlice";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import ProductCardNew from "./ProductCardNew";
import {
  ArrowCircleRightOutlined,
  Expand,
  ExpandCircleDown,
  ExpandCircleDownOutlined,
  ExpandCircleDownRounded,
} from "@mui/icons-material";
import { FaChevronDown, FaExpand } from "react-icons/fa6";

function Checkout({
  setCart,
  setTempTotal,
  activeStep,
  handleBack,
  handleNext,
  steps,
  fees,
  discount,
  spinner1,
  transportFees,
}) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, error, profile } = useSelector((state) => state.user);
  const { cproducts } = useSelector((state) => state.checkoutproducts);
  const { deleteItemStatus, deleteItemInfo, deleteItemError } = useSelector(
    (state) => state.checkoutdelete
  );
  const [spinner, setSpinner] = useState(false);

  const toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const [buyProducts, setBuyProducts] = useState([]);
  const [rentProducts, setRentProducts] = useState([]);
  const [rentEstimates, setRentEstimates] = useState([]);

  const [rentTotal, setRentTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [buyTotal, setBuyTotal] = useState(0);
  const [rentExpand, setRentExpand] = useState(null);

  // let buyTotal = 0;

  useEffect(() => {
    // dispatch(reset());
    if (userInfo) {
      console.log("RELLOD");
      let cart = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (cart.length > 0) {
        calculateTotal();
        setSpinner(true);
        // dispatch(
        //   fetchProducts({
        //     token: localStorage.getItem("inktoken"),
        //     userId: userInfo._id,
        //   })
        // );
      } else {
        setBuyProducts([]);
        setRentProducts([]);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    calculateTotal();
  }, [fees]);

  const calculateDateDifference = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Calculate the difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert the time difference to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Calculate months, weeks, and additional days
    // const months = Math.floor(daysDifference / 30);
    // const remainingDays = daysDifference % 30;
    // const weeks = Math.floor(remainingDays / 7);
    // const additionalDays = remainingDays % 7;

    // return { days: additionalDays, weeks, months };
    return daysDifference + 1;
  };

  useEffect(() => {
    if (cproducts) {
      console.log("cproducts k2l ", cproducts);
      if (cproducts.length > 0) {
        console.log("CPRODUCTS", cproducts);
        let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        setBuyProducts(
          cproducts.filter(
            (item) =>
              item.itemToSell &&
              cartItems &&
              cartItems.length > 0 &&
              cartItems.find((ct) => ct.id === item._id)?.itemType === "buy"
          )
        );
        setRentProducts(
          cproducts.filter(
            (item) =>
              item.itemForRent &&
              cartItems &&
              cartItems.length > 0 &&
              cartItems.find((ct) => ct.id === item._id)?.itemType === "rent"
          )
        );
        console.log(
          "CPRODUCTS",
          cproducts.filter((item) => item.itemForRent)
        );
      }
    }
  }, [cproducts]);

  useEffect(() => {
    calculateTotal();
  }, [buyProducts, rentProducts]);

  useEffect(() => {
    if (rentProducts.length > 0) {
      let rentalItem = [];
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (cartItems && cartItems.length > 0) {
        rentalItem = cartItems.filter((item) => item.itemType === "rent");
      }
      console.log(cartItems);
      rentProducts.map((item, ind) => {
        const indRentalItem = rentalItem.filter(
          (itemm) => itemm.id === item._id
        );
        if (indRentalItem.length > 0) {
          let rentPeriodInDays = calculateDateDifference(
            indRentalItem[0].checkoutRentalDateRange[0],
            indRentalItem[0].checkoutRentalDateRange[1]
          );
          let totalRentPrice = 0;

          if (rentPeriodInDays > 0) {
            // if (item.itemDailyPrice != 0) {
            //   totalRentPrice +=
            //     parseInt(item.itemDailyPrice) * parseInt(rentPeriodInDays);
            // } else if (item.itemWeekly != 0) {
            //   totalRentPrice +=
            //     parseInt(item.itemWeeklyPrice) *
            //     parseFloat(rentPeriodInDays / 7);
            // } else {
            //   totalRentPrice +=
            //     item.itemMonthlyPrice * parseFloat(rentPeriodInDays / 30);
            // }
            const dailyPrice = parseInt(item.itemDailyPrice) || 0;
            const weeklyPrice = parseInt(item.itemWeeklyPrice) || 0;
            const monthlyPrice = parseInt(item.itemMonthlyPrice) || 0;

            const weeks = Math.floor(rentPeriodInDays / 7);
            const remainingDays = rentPeriodInDays % 7;

            let semirent = rentPeriodInDays

            while (semirent > 0) {
                if (semirent > 17) {  // More than 3 weeks
                    totalRentPrice += monthlyPrice;
                    semirent -= 28;
                } else if (semirent > 3) {  // More than 3 days
                    totalRentPrice += weeklyPrice;
                    semirent -= 7;
                } else {
                    totalRentPrice += dailyPrice * semirent;
                    semirent = 0;
                }
            }

            // if (monthlyPrice && rentPeriodInDays >= 28) {
            //   const months = Math.floor(rentPeriodInDays / 28);
            //   const remainingDaysAfterMonths = rentPeriodInDays % 28;
            //   totalRentPrice += monthlyPrice * months;

            //   if (weeklyPrice && remainingDaysAfterMonths >= 7) {
            //     totalRentPrice +=
            //       weeklyPrice * Math.floor(remainingDaysAfterMonths / 7);
            //     totalRentPrice += dailyPrice * (remainingDaysAfterMonths % 7);
            //   } else {
            //     totalRentPrice += dailyPrice * remainingDaysAfterMonths;
            //   }
            // } else if (weeklyPrice && rentPeriodInDays >= 7) {
            //   totalRentPrice += weeklyPrice * weeks;
            //   totalRentPrice += dailyPrice * remainingDays;
            // } else {
            //   totalRentPrice += dailyPrice * rentPeriodInDays;
            // }

            if (item.itemFuelPrice) {
              totalRentPrice += item.itemFuelPrice;
            }
            if (item.itemMaintenancePrice) {
              const hoursPerDay = 24; // Number of hours in a day
              const baseMaintenancePrice = item.itemMaintenancePrice; // The base maintenance price for 250 hours

              // Calculate the total rental period in hours
              const totalRentPeriodInHours = rentPeriodInDays * hoursPerDay;

              // Calculate the number of 250-hour intervals
              const intervals = Math.ceil(totalRentPeriodInHours / 250);

              // Calculate the total maintenance cost
              const maintenanceCost = intervals * baseMaintenancePrice;

              // Add to the total rent price
              totalRentPrice += maintenanceCost;
              console.log(`Total Maintenance Cost: ${totalRentPeriodInHours}`);
            }
            if (item.itemCleaningPrice) {
              totalRentPrice += item.itemCleaningPrice;
            }

            setRentEstimates((prev) => {
              // Check if [item._id] is already present in the state
              if (prev) {
                const isItemPresent = prev.some((rentEstimate) =>
                  rentEstimate.hasOwnProperty(item._id)
                );

                //console.log("debugger", isItemPresent);

                // If [item._id] is not present, add it
                if (!isItemPresent) {
                  return [
                    ...prev,
                    {
                      [item._id]: {
                        rentalPeriodRange: [
                          indRentalItem[0]?.checkoutRentalDateRange[0],
                          indRentalItem[0]?.checkoutRentalDateRange[1],
                        ],
                        rentPeriodInDays: rentPeriodInDays,
                        totalRentPrice: totalRentPrice,
                      },
                    },
                  ];
                }

                // If [item._id] is already present, return the unchanged state
                return prev;
              } else {
                // //console.log(
                //   "hello first",
                //   rentPeriodInDays,
                //   totalRentPrice
                // );
                return [
                  ...prev,
                  {
                    [item._id]: {
                      rentalPeriodRange: [
                        indRentalItem[0]?.checkoutRentalDateRange[0],
                        indRentalItem[0]?.checkoutRentalDateRange[1],
                      ],
                      rentPeriodInDays: rentPeriodInDays,
                      totalRentPrice: totalRentPrice,
                    },
                  },
                ];
              }
            });
          }
        }
      });
    } else {
      // console.log("SDSD")
      setRentEstimates([]);
    }

    setSpinner(false);
  }, [rentProducts]);

  useEffect(() => {
    console.log("rent estimates", rentEstimates);
    calculateTotal();
  }, [rentEstimates]);

  const handleProductRemove = (id) => {
    //console.log("Product To be Removed ", id);
    swal({
      title: "Are you sure?",
      text: "This Item will be Removed from Your Cart",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setSpinner(true);
        let token = localStorage.getItem("inktoken");
        let cart = JSON.parse(localStorage.getItem("cartItems")) || [];
        if (cart.length > 0) {
          // let ind = cart.findIndex(x=>x.id === id);
          // if (ind > -1) {
          //   cart.splice(ind, 1);
          //   // setCart(cart);
          // }
          let cartIteems = JSON.parse(localStorage.getItem("cartItems"));
          if (cartIteems && cartIteems.length > 0) {
            cartIteems = cartIteems.filter((item) => item.id !== id);
          }
          localStorage.setItem("cartItems", JSON.stringify(cartIteems));
          // sessionStorage.setItem("closetcart", JSON.stringify(cart));
          dispatch(deleteCheckoutItem({ token, id }));
          if (cart.length === 0) {
            setBuyProducts([]);
            setRentProducts([]);
          }
        }
      }
    });
  };

  if (deleteItemInfo) {
    dispatch(resetCheckoutDelete());
    toast.success("Product Successfully Removed", toastOption);
    setTimeout(() => {
      setSpinner(false);
      let token = localStorage.getItem("inktoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
        dispatch(
          fetchProducts({
            token: localStorage.getItem("inktoken"),
            userId: userInfo._id,
          })
        );
      } else {
        navigate("/signup", { replace: true });
      }
      calculateTotal();
    }, 5000);
  }

  const calculateTotal = () => {
    console.log("idhar raaja", rentEstimates);
    let subTotal =
      rentEstimates &&
      rentEstimates.length > 0 &&
      rentEstimates.reduce((sum, rentObj) => {
        const rentValues = Object.values(rentObj)[0]; // Extracting values from the object
        console.log(rentValues);
        return sum + rentValues.totalRentPrice;
      }, 0);
    console.log("SUBTOTAL", subTotal);
    setRentTotal(subTotal);

    let buyT =
      buyProducts &&
      buyProducts.reduce(
        (total, product) => total + Number(product.itemAmount),
        0
      );
    console.log(buyProducts);
    let buyTotal = buyT;
    setBuyTotal(buyTotal);
    subTotal += buyT;
    setSubTotal(subTotal);

    // const tax = 0.1 * subTotal; // 10% tax (you can adjust this)
    setTax(0);

    const totalAmount = subTotal + tax + transportFees;

    if (discount > 0) {
      setTotalAmount(totalAmount + (fees * discount) / 100);
      console.log(totalAmount);
      setTempTotal(totalAmount + (fees * discount) / 100);
    } else {
      setTotalAmount(totalAmount + fees);
      console.log(totalAmount);
      setTempTotal(totalAmount + fees);
    }

    return { subTotal, tax, totalAmount };
  };

  const calculateDays = (start, end, type) => {
    const difference = end - start;
    console.log("diiff", difference, type);
    if (type === "days") {
      return Math.floor(difference / (1000 * 60 * 60 * 24)) + 1;
    }
    if (type === "months") {
      return Math.floor(difference / (1000 * 60 * 60 * 24 * 30.44));
    }
    if (type === "weeks") {
      return Math.floor(difference / (1000 * 60 * 60 * 24 * 7));
    }
  };

  // const { subTotal, tax, totalAmount } = calculateTotal();
  return (
    <>
      {spinner || spinner1 ? (
        <Spinner
          spinnerColor={"var(--secondary-color)"}
          spinnerBgTranparent={true}
        />
      ) : (
        <Container
          maxWidth="lg"
          sx={{
            margin: "2rem auto",
            marginTop: "4rem",
            paddingLeft: 0,
            paddingRight: 0,
          }}
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          {/* <Divider sx={{ marginTop: "-1rem" }} /> */}
          {/* <br /> */}
          {/* <Typography variant="h3" fontWeight={700} mt={"1rem"} mb={"3rem"}>
          Equipments Overview
        </Typography> */}
          {(buyProducts && buyProducts.length > 0) ||
          (rentProducts && rentProducts.length > 0) ? (
            <Grid
              container
              spacing={3}
              sx={{
                flexGrow: 1,
                padding: 0,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                xs={12}
                sm={4}
                md={8}
                sx={{
                  borderRight: "1px solid #ddd",
                }}
              >
                {buyProducts && buyProducts.length > 0 && (
                  <Paper
                    elevation={0}
                    sx={{ padding: "1.5rem", borderRadius: "10px" }}
                  >
                    <Divider
                      sx={{
                        fontSize: "20px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                      textAlign="left"
                    >
                      Equipment
                    </Divider>

                    {buyProducts &&
                      buyProducts.map((product) => (
                        <ProductCardNew
                          key={product._id}
                          product={product}
                          handleProductRemove={handleProductRemove}
                        />
                      ))}
                    {/* <Divider sx={{ margin: "2rem 0" }} /> */}
                  </Paper>
                )}
                {/* <br /> */}
                {rentProducts && rentProducts.length > 0 && (
                  <Paper
                    elevation={0}
                    sx={{ padding: "1.5rem", borderRadius: "10px" }}
                  >
                    <Divider
                      sx={{
                        fontSize: "20px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                      textAlign="left"
                    >
                      Rental Equipment
                    </Divider>

                    {rentProducts &&
                      rentProducts.map((product) => {
                        return (
                          <ProductCardNew
                            handleProductRemove={handleProductRemove}
                            key={product._id}
                            product={product}
                            rentEstimates={rentEstimates}
                          />
                        );
                      })}
                    {/* <Divider sx={{ margin: "2rem 0" }} /> */}
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper
                  elevation={0}
                  style={{
                    padding: "1rem 2rem",
                    paddingBottom: "2rem",
                    background: "var(--black-color)",
                    color: "var(--white-color)",
                    // minHeight: "100vh",
                    // border: "1px solid #ddd",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h3" fontWeight={700} margin={"1rem"}>
                    Summary
                  </Typography>
                  <TableContainer sx={{ overflow: "hidden" }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: 700,
                              fontSize: "12px",
                              textTransform: "uppercase",
                              letterSpacing: 1.5,
                              color: "var(--grey-color)",

                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Description
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: 700,
                              fontSize: "12px",
                              textTransform: "uppercase",
                              letterSpacing: 1.5,
                              color: "var(--grey-color)",

                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {buyProducts && buyProducts.length > 0 && (
                          <TableRow sx={{ overflowX: "hidden" }}>
                            <TableCell
                              sx={{
                                color: "var(--white-color)",
                                border: "none",
                                borderBottomColor: "var(--grey-color)",
                              }}
                            >
                              Buy Total
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color: "var(--white-color)",
                                border: "none",
                                borderBottomColor: "var(--grey-color)",
                              }}
                            >
                              ${parseInt(buyTotal).toLocaleString("en-US")}
                            </TableCell>
                          </TableRow>
                        )}
                        {rentProducts && rentProducts.length > 0 && (
                          <>
                          <TableRow>
                            <TableCell sx={{
                                      color: "var(--white-color)",
                                      border: "none",
                                      borderBottomColor: "var(--grey-color)",
                                      // width: "100%"
                                      // minWidth: "150px",
                                    }}
                                    align="left">
                            <Accordion
                              sx={{
                                background: "Var(--black-color)",
                                color: "var(--white-color)",
                                margin: 0,
                                padding:0
                                // overflowX: "hidden",
                              }}
                              onChange={(e, expanded) =>
                                setRentExpand(expanded)
                              }
                              elevation={0}
                            >
                              <AccordionSummary
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="summary-accordion"
                                sx={{
                                  margin: 0,
                                  padding: 0,
                                  border: "none",
                                  boxShadow: "none",
                                  // width: "159%",
                                  display: "flex",
                                 
                                }}
                              >
                                <ExpandCircleDownRounded
                                  sx={{
                                    position: "absolute",
                                    left: -16,
                                    top: 11,
                                    transform: rentExpand
                                      ? "rotate(180deg)"
                                      : "rotate(0)",
                                    fontSize: 14,
                                    color: "var(--grey-color)",
                                  }}
                                />
                                {/* <TableRow sx={{ overflowX: "hidden" }}> */}
                                  {/* <TableCell
                                    sx={{
                                      color: "var(--white-color)",
                                      border: "none",
                                      borderBottomColor: "var(--grey-color)",
                                      width: "100%"
                                      // minWidth: "150px",
                                    }}
                                    align="left"
                                  > */}
                                    Rent Total
                                  {/* </TableCell> */}
                                  
                                {/* </TableRow> */}
                              </AccordionSummary>
                              <AccordionDetails
                                className="summary-details"
                                sx={{ padding: 0, fontSize: 12 }}
                              >
                                {rentProducts.map((product, ind) => (
                                  <Box
                                    marginBottom={"1rem"}
                                    sx={{
                                      color: "var(--grey-color)",
                                      position: "relative",
                                      left: "0rem",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        position: "relative",
                                        // left: "2rem",
                                      }}
                                    >
                                      <Typography variant="h6" fontSize="12px">
                                        Equipment:&nbsp;&nbsp;
                                        {product.itemName}
                                      </Typography>
                                      {(() => {
                                        const rentPeriodInDays =
                                          calculateDateDifference(
                                            new Date(
                                              rentEstimates &&
                                                rentEstimates.length > 0 &&
                                                rentEstimates[ind] &&
                                                rentEstimates[ind][
                                                  product._id
                                                ] &&
                                                rentEstimates[ind][product._id]
                                                  .rentalPeriodRange[0]
                                            ),
                                            new Date(
                                              rentEstimates &&
                                                rentEstimates.length > 0 &&
                                                rentEstimates[ind] &&
                                                rentEstimates[ind][
                                                  product._id
                                                ] &&
                                                rentEstimates[ind][product._id]
                                                  .rentalPeriodRange[1]
                                            )
                                          );
                                        const monthlyPrice =
                                          parseInt(product.itemMonthlyPrice) ||
                                          0;
                                        const weeklyPrice =
                                          parseInt(product.itemWeeklyPrice) ||
                                          0;
                                        const dailyPrice =
                                          parseInt(product.itemDailyPrice) || 0;

                                        let semirent = rentPeriodInDays

                                        let months = 0;
                                        let weeks = 0;
                                        let days = 0;

                                          while (semirent > 0) {
                                              if (semirent > 17) {  // More than 2.5 weeks
                                                  months += 1;
                                                  semirent -= 28;
                                              } else if (semirent > 3) {  // More than 3 days
                                                  weeks += 1;
                                                  semirent -= 7;
                                              } else {
                                                  days += 1 * semirent;
                                                  semirent = 0;
                                              }
                                          }

                                       

                                        return (
                                          <>
                                            {monthlyPrice && months > 0 ? (
                                              <Typography
                                                variant="h6"
                                                fontSize="12px"
                                              >
                                                Monthly Price: ${monthlyPrice} x{" "}
                                                {months} month
                                                {months > 1 ? "s" : ""}
                                              </Typography>
                                            ) : (
                                              ""
                                            )}
                                            {weeklyPrice && weeks > 0 ? (
                                              <Typography
                                                variant="h6"
                                                fontSize="12px"
                                              >
                                                Weekly Price: ${weeklyPrice} x{" "}
                                                {weeks} week
                                                {weeks > 1 ? "s" : ""}
                                              </Typography>
                                            ) : (
                                              ""
                                            )}
                                            {dailyPrice && days > 0 ? (
                                              <Typography
                                                variant="h6"
                                                fontSize="12px"
                                              >
                                                Daily Price: ${dailyPrice} x{" "}
                                                {days} day{days > 1 ? "s" : ""}
                                              </Typography>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })()}

                                      <Typography variant="h6" fontSize="12px">
                                        Cleaning Fees:&nbsp;&nbsp; $
                                        {product.itemCleaningPrice
                                          ? product.itemCleaningPrice
                                          : 0}
                                      </Typography>
                                      <Typography variant="h6" fontSize="12px">
                                        Maintenance Fees:&nbsp;&nbsp; $
                                        {product.itemMaintenancePrice
                                          ? Math.ceil(
                                              (calculateDays(
                                                new Date(
                                                  rentEstimates &&
                                                    rentEstimates.length > 0 &&
                                                    rentEstimates[ind] &&
                                                    rentEstimates[ind][
                                                      product._id
                                                    ] &&
                                                    rentEstimates[ind][
                                                      product._id
                                                    ].rentalPeriodRange[0]
                                                ),
                                                new Date(
                                                  rentEstimates &&
                                                    rentEstimates.length > 0 &&
                                                    rentEstimates[ind] &&
                                                    rentEstimates[ind][
                                                      product._id
                                                    ] &&
                                                    rentEstimates[ind][
                                                      product._id
                                                    ].rentalPeriodRange[1]
                                                ),
                                                product.itemDailyPrice &&
                                                  product.itemDailyPrice !== 0
                                                  ? "days"
                                                  : product.itemWeeklyPrice &&
                                                    product.itemWeeklyPrice !==
                                                      0
                                                  ? "weeks"
                                                  : "months"
                                              ) *
                                                24) /
                                                250
                                            ) * product.itemMaintenancePrice
                                          : 0}
                                      </Typography>
                                      <Typography variant="h6" fontSize="12px">
                                        Fuel Fees:&nbsp;&nbsp; $
                                        {product.itemFuelPrice
                                          ? product.itemFuelPrice
                                          : 0}
                                      </Typography>
                                      <Typography variant="h6" fontSize="12px">
                                        Rental :&nbsp;&nbsp;
                                        {calculateDays(
                                          new Date(
                                            rentEstimates &&
                                              rentEstimates.length > 0 &&
                                              rentEstimates[ind] &&
                                              rentEstimates[ind][product._id] &&
                                              rentEstimates[ind][product._id]
                                                .rentalPeriodRange[0]
                                          ),
                                          new Date(
                                            rentEstimates &&
                                              rentEstimates.length > 0 &&
                                              rentEstimates[ind] &&
                                              rentEstimates[ind][product._id] &&
                                              rentEstimates[ind][product._id]
                                                .rentalPeriodRange[1]
                                          ),
                                          product.itemDailyPrice &&
                                            product.itemDailyPrice !== 0
                                            ? "days"
                                            : product.itemWeeklyPrice &&
                                              product.itemWeeklyPrice !== 0
                                            ? "weeks"
                                            : "months"
                                        )}{" "}
                                        {product.itemDailyPrice &&
                                        product.itemDailyPrice !== 0
                                          ? "days"
                                          : product.itemWeeklyPrice &&
                                            product.itemWeeklyPrice !== 0
                                          ? "weeks"
                                          : "months"}{" "}
                                      </Typography>
                                    </Box>
                                  </Box>
                                ))}
                              </AccordionDetails>
                            </Accordion>
                            </TableCell>
                            <TableCell
                                    sx={{
                                      color: "var(--white-color)",
                                      border: "none",
                                      borderBottomColor: "var(--grey-color)",
                                      // padding: 0
                                    }}
                                    align="right"
                                  >
                                    $
                                    {parseInt(rentTotal).toLocaleString(
                                      "en-US"
                                    )}
                                  </TableCell>
                            </TableRow>
                          </>
                        )}
                        <TableRow>
                          {/* <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Subtotal
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                            align="right"
                          >
                            ${parseInt(subTotal).toFixed(2)}
                          </TableCell> */}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Transportation
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                            align="right"
                          >
                            TBD
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Fees
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                            align="right"
                          >
                            ${parseInt(fees).toLocaleString("en-US")}
                          </TableCell>
                        </TableRow>
                        {discount > 0 && (
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "var(--white-color)",
                                border: "none",
                                borderBottomColor: "var(--grey-color)",
                              }}
                            >
                              {(userInfo && userInfo.userDetailsId.referCount)?"Referral Discount":"Promo Discount"} ({discount + "%"})
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "var(--white-color)",
                                border: "none",
                                borderBottomColor: "var(--grey-color)",
                              }}
                              align="right"
                            >
                              - $
                              {parseInt((fees * discount) / 100).toLocaleString(
                                "en-US"
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Tax
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                              minWidth: "max-content",
                            }}
                            align="right"
                          >
                            {/* ${parseInt(tax).toFixed(2)} */}
                            {activeStep === steps.length - 1
                              ? parseInt(tax).toLocaleString("en-US")
                              : "TBD"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "700",
                              textTransform: "uppercase",
                              letterSpacing: 1.5,
                              color: "var(--white-color)",
                              border: "none",
                              borderTop: "1px solid var(--grey-color)",
                              borderBottom: "none",
                            }}
                            align="left"
                          >
                            Total
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "700",
                              border: "none",
                              borderTop: "1px solid var(--grey-color)",
                              borderBottom: "none",
                              color: "var(--white-color)",
                              position: "relative",
                            }}
                            align="right"
                          >
                            TBD
                            {/* ${Number(totalAmount).toFixed(2)} */}
                            {/* <span
                              style={{
                                fontSize: 13,
                              }}
                            >
                              TBD
                            </span> */}
                            {/* </span> */}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      onClick={() => handleNext()}
                      disabled={
                        !steps.includes("Information")
                          ? activeStep === 2
                          : activeStep === 3
                      }
                      sx={{
                        mt: 2,
                        background: "var(--secondary-color)",
                        "&: hover": {
                          background: "var(--grey-color)",
                          color: "var(--black-color)",
                        },
                      }}
                    >
                      Proceed &nbsp;
                      <ArrowCircleRightOutlined />
                    </Button>
                  </Box>
                </Paper>

                {/* <Paper
                elevation={3}
                style={{
                  marginTop: "2rem",
                  padding: "1rem 2rem",
                  paddingBottom: "6.5rem",
                  borderRadius: "10px",
                }}
              >
                <h2>Payment Information</h2>
                <form>
                  <TextField label="Full Name" fullWidth margin="normal" />
                  <TextField label="Email" fullWidth margin="normal" />
                  <TextField
                    label="Address"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                  />
                  <TextField
                    label="Credit Card Number"
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Expiration Date"
                    fullWidth
                    margin="normal"
                  />
                  <TextField label="CVV" fullWidth margin="normal" />
                  <Button variant="contained" color="primary" fullWidth>
                    Submit
                  </Button>
                </form>
              </Paper> */}
              </Grid>
            </Grid>
          ) : (
            <Box
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "60vh",
                flexDirection: "column",
              }}
            >
              <Typography variant="h3">
                {userInfo
                  ? "You have no equipment in your cart!"
                  : "Log In to access your cart."}
              </Typography>
              {!userInfo ? (
                <Link to={"/login"}>
                  <Button
                    sx={{ marginTop: "1rem" }}
                    variant="contained"
                    color="secondary"
                  >
                    Log In
                  </Button>
                </Link>
              ) : (
                <Link to={"/shop"}>
                  <Button
                    sx={{ marginTop: "1rem" }}
                    variant="contained"
                    color="secondary"
                  >
                    Shop
                  </Button>
                </Link>
              )}
            </Box>
          )}
        </Container>
      )}
    </>
  );
}

export default Checkout;
