import {
  Box,
  Button,
  Container,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Backdrop,
  Fade,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FileUploadDragAndDrop from "./FileUploadDragAndDrop";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import "./CheckoutRentalAgreement.css";
import { CheckCircle } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  docuSign,
  getdocuSign,
  resetBrand,
} from "../StateSlices/docusignSlice";
import { useNavigate } from "react-router-dom";
import { getuserInfo } from "../StateSlices/getuserSlice";
import Spinner from "../Spinner/Spinner";
import { toast } from "react-toastify";
import {} from "@mui/material";
import { FaFileAlt } from "react-icons/fa";
import docusignTemplateImage from "../../assets/docusign-sample.jpg";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "75vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};

const CheckoutRentalAgreement = ({
  rrent,
  fileAgreement,
  setFileAgreement,
  setActiveStep,
  docusignDone,
  setDocusignDone,
  setSaveInsurance,
  saveInsurance,
  docuSignByOwn,
  setDocuSignByOwn,
  docusignAgreement,
  setDocuSignAgreement,
  saveDocuSignInsurance,
  setSaveDocuSignInsurance
}) => {
  const dispatch = useDispatch();
  const { dsInfo, dsError, dsFileInfo } = useSelector(
    (state) => state.docusign
  );
  const { userInfo, error, profile } = useSelector((state) => state.user);
  const [Files, SetFiles] = useState(fileAgreement ? [fileAgreement] : []);
  const [filePreviewModal, setFilePreviewModal] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [byown, setByown] = useState(false);

  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [savedType, setSavedType] = useState("");

  const [fileSet, setFileSet] = useState(false);

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const openDocusign = () => {
    if (userInfo) {
      setSpinner(true);
      setDocuSignByOwn(true);
      toast.info("Opening Docusign Interface...", toastOption);
      const token = localStorage.getItem("inktoken");
      const values = {
        signerEmail: userInfo.userDetailsId.userEmail,
        signerName: userInfo.userDetailsId.userFName,
      };
      setSavedType("docusign");
      dispatch(docuSign({ token, values }));
    }
  };

  const uploadFileAgreement = () => {
    window.scrollTo(0, 0);
    setFileAgreement(Files[0] ? Files[0] : data);
    toast.success("File Saved Successfully", toastOption);
    setFilePreviewModal(false);
    // setActiveStep((prev) => prev + 1);
  };

  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    if (userInfo && userInfo.userDetailsId.docusignAgreement) {
      setSavedType("docusign");
      dispatch(
        getdocuSign({ token, id: userInfo.userDetailsId.docusignAgreement })
      );
      // setSpinner(false);
      console.log("GH");
      setSpinner(true);
    }

    if(localStorage.getItem('localUpload')){
      // let localData = JSON.parse(localStorage.getItem('localUpload'))
      let localData = (() => { try { return new URL(localStorage.getItem('localUpload')), localStorage.getItem('localUpload'); } catch { try { return JSON.parse(localStorage.getItem('localUpload')); } catch { return null; } } })();

      setFileAgreement(localData);
    }
    console.log("HH");
    setSpinner(false);
    // if (userInfo && userInfo.userDetailsId.agreement) {
    //   // setData(userInfo.userDetailsId.agreement.replace("http","https"))
    //   let agreement = userInfo.userDetailsId.agreement;
    //   if (agreement.startsWith("http://")) {
    //     agreement = agreement.replace("http://", "https://");
    //   } else if (!agreement.startsWith("https://")) {
    //     // If the URL doesn't start with http:// or https://, assume it's relative and prepend https://
    //     agreement = "https://" + agreement;
    //   }
    //   setSavedType("file");
    //   setFileSet(true);
    //   setData(agreement);
    //   // if(!fileAgreement) setFilePreviewModal(true);
    //   setSpinner(false);
    // } else {
    //   console.log("HH");
    //   setSpinner(false);
    // }
  }, []);

  useEffect(() => {
    if (dsFileInfo) {
      setSpinner(false);
      setData(dsFileInfo);
      setDocuSignAgreement(userInfo.userDetailsId.docusignAgreement);
      dispatch(resetBrand());
      // setActiveStep(2);
      console.log(userInfo,dsFileInfo);
      setDocusignDone(true);
      toast.success(
        "DocuSign Document Signed Successfully!",
        toastOption
      );
    }
  }, [dsFileInfo]);

  useEffect(() => {
    if (dsError) {
      console.log("ERROR");
      setSpinner(false);
      dispatch(resetBrand());
    }
  }, [dsError]);

  // useEffect(() => {
  //   let token = localStorage.getItem("inktoken");
  //   if (token) {
  //     setSpinner(true);
  //     dispatch(getuserInfo({ token: token }));
  //     window.scrollTo(0, 0);
  //   } else {
  //     navigate("/login");
  //   }
  // }, []);

  // useEffect(() => {
  //   if (userInfo && userInfo.userDetailsId) {
  //     console.log(userInfo);
  //     setSpinner(false);
  //   }
  // }, [userInfo]);

  useEffect(() => {
    if (dsInfo) {
      setSpinner(false);
      window.location.href = dsInfo;
      // const width = window.innerWidth * 0.75;
      // const height = window.innerHeight * 0.75;
      // const left = (window.innerWidth - width) / 2;
      // const top = (window.innerHeight - height) / 2;

      // const popupWindow = window.open(
      //   dsInfo, // Replace with your actual hyperlink
      //   "_blank",
      //   `width=${width}, height=${height}, left=${left}, top=${top}`
      // );

      // window.addEventListener("beforeunload", () => {
      //   if (popupWindow && !popupWindow.closed) {
      //     popupWindow.close();
      //   }
      // });

      // // Set scroll position after 200px scrollY
      // setTimeout(() => {
      //   if (popupWindow && !popupWindow.closed) {
      //     popupWindow.scrollTo(0, 200);
      //   }
      // }, 100); // Adjust the delay as needed
    }
  }, [dsInfo]);

  useEffect(() => {
    console.log(Files);
  }, [Files]);

  return (
    <Container
      maxWidth={"lg"}
      sx={{ margin: "2rem auto", paddingLeft: 0, paddingRight: 0 }}
      data-aos="fade-in"
      data-aos-duration="1000"
    >
      {/* <Typography variant="h3" fontWeight={700} mt={"1rem"} mb={"3rem"}>
        Rental Agreement Information
      </Typography> */}
      {spinner ? (
        <Spinner pos={"static"} />
      ) : (
        <>
          {rrent && rrent.length > 0 ? (
            <Box
              component={"section"}
              padding={"1rem"}
              margin={".5rem"}
              sx={{
                // background: "#f3a9502b",
                borderRadius: "10px",
              }}
            >
              {(
                (userInfo &&
                  userInfo.userDetailsId.docusignAgreement &&
                  !docuSignByOwn &&
                  !byown)) && (
                <>
                  <Box component={"section"} className="upload-rental-ins-con">
                    <Box marginBottom={".5rem"}>
                      <Typography
                        variant="h3"
                        margin={"0 0 1rem 0"}
                        textAlign={"center"}
                        fontWeight={700}
                      >
                        Use Previous Rental Agreement
                      </Typography>
                      <Box
                        maxWidth={"75%"}
                        width={"auto"}
                        borderRadius={"10px"}
                        maxHeight={"70%"}
                        textAlign={"center"}
                        margin={"auto"}
                      >
                        <img
                          src={docusignTemplateImage}
                          width={"100%"}
                          height={"100%"}
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                          alt="docusign agreement snapshot"
                        />
                      </Box>
                    </Box>

                   
                    {(savedType === "file" || savedType === "docusign") && (
                      <Box textAlign={"center"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              name="saveDocument"
                              value="yes"
                              defaultChecked={
                                userInfo.userDetailsId.saveDocuSignInsurance !==
                                undefined
                                  ? userInfo.userDetailsId.saveDocuSignInsurance
                                  : true
                              }
                              onChange={() => setSaveDocuSignInsurance((prev) => !prev)}
                            />
                          }
                          label="Save insurance document for easy checkout next time"
                        />
                      </Box>
                    )}
                  </Box>
                  <Typography
                    variant="h3"
                    fontWeight={"700"}
                    textAlign={"center"}
                    color={"var(--dark-grey-color)"}
                    my={6}
                  >
                    OR
                  </Typography>
                </>
              )}

              {/* <Box component={"section"} className="upload-rental-ins-con">
                <Box marginBottom={".5rem"}>
                  <Typography
                    variant="h3"
                    margin={"0 0 2rem 0"}
                    textAlign={"center"}
                    fontWeight={700}
                  >
                    Upload Rental Insurance (Optional)
                  </Typography>
                </Box>

                <div className="checkout-file-upload-con">
                  <FileUploadDragAndDrop
                    SetFiles={SetFiles}
                    Files={Files}
                    filePreviewModal={filePreviewModal}
                    setFilePreviewModal={setFilePreviewModal}
                    setFileAgreement={setFileAgreement}
                    setByown={setByown}
                    userInfo={userInfo}
                    setData={setData}
                    docusignDone={docusignDone}
                    setDocusignDone={setDocusignDone}
                    setDocuSignByOwn={setDocuSignByOwn}
                  />
                </div>

                {byown && (
                  <Box textAlign={"center"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          name="saveDocument"
                          value="yes"
                          defaultChecked={
                            userInfo.userDetailsId.insuranceCheckBox !==
                            undefined
                              ? userInfo.userDetailsId.insuranceCheckBox
                              : true
                          }
                          onChange={() => setSaveInsurance((prev) => !prev)}
                        />
                      }
                      label="Save insurance document for easy checkout next time"
                    />
                  </Box>
                )}
              </Box> */}
              <div className="checkout-docusign-con">
                <div
                  className="checkout-file-upload-con"
                  style={{ margin: "1rem auto", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column" }}
                >
                  <Typography
                    variant="h3"
                    textAlign={"center"}
                    margin={"1rem 0 1rem 0"}
                    fontWeight={700}
                  >
                    Dizel DocuSign Agreement
                  </Typography>
                  {!docusignDone || !docuSignByOwn ? (
                    <Box sx={{ textAlign: "center" }}>
                      <Button
                        onClick={openDocusign}
                        // disabled={Files.length > 0 ? true : false}
                        variant="contained"
                        centerRipple
                        sx={{
                          background: "var(--secondary-color)",
                          mt: 1,
                          padding: "8px 45px",
                          borderRadius: "30px",
                          fontSize: "1.1rem",
                        }}
                      >
                        Sign
                      </Button>
                    </Box>
                  ) : (
                    <>
                      <Typography
                        color={"green"}
                        variant="body2"
                        textAlign={"center"}
                        // marginBottom={"1rem"}
                      >
                        Document Signed Successfully!
                        <br />
                        You'll receive an email once checkout completes.
                      </Typography>
                      <Box
                        maxWidth={"75%"}
                        width={"auto"}
                        borderRadius={"10px"}
                        // maxHeight={"70%"}
                        textAlign={"center"}
                        // margin={"auto"}
                        margin={"1rem auto"}
                        // marginBottom={"2.5rem"}
                      >
                        <img
                          src={docusignTemplateImage}
                          width={"100%"}
                          height={"100%"}
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                          alt="docusign agreement snapshot"
                        />
                      </Box>
                    </>
                  )}
                  {docusignDone && docuSignByOwn && (
                    <Box textAlign={"center"}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            name="saveDocument"
                            value="yes"
                            defaultChecked={
                              userInfo.userDetailsId.saveDocuSignInsurance !==
                              undefined
                                ? userInfo.userDetailsId.saveDocuSignInsurance
                                : true
                            }
                            onChange={() => setSaveDocuSignInsurance((prev) => !prev)}
                          />
                        }
                        label="Save insurance document for easy checkout next time"
                      />
                    </Box>
                  )}
                </div>
              </div>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    if (!docusignDone) {
                      toast.error(
                        "Please Sign Document First",
                        toastOption
                      );
                      return;
                    }
                    // if (data && !docusignDone) {
                    //   uploadFileAgreement();
                    // }
                    setActiveStep(3);
                  }}
                  // disabled={activeStep === 2}
                  sx={{
                    mr: 1,
                    background: "var(--secondary-color)",
                    "&: hover": {
                      background: "var(--grey-color)",
                      color: "var(--black-color)",
                    },
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography variant="h3" fontWeight={700}>
              Coming Soon!
            </Typography>
          )}
        </>
      )}
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={filePreviewModal}
        onClose={() => setFilePreviewModal(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={filePreviewModal}>
          <Box sx={modalStyle}>
            {Files.length > 0 || data ? (
              <>
                <DocViewer
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: false,
                      //  retainURLParams: false
                    },
                  }}
                  style={{ height: "94%", borderRadius: "10px" }}
                  pluginRenderers={DocViewerRenderers}
                  documents={[
                    {
                      uri:
                        (Files[0] && typeof Files[0] != "string") ||
                        (byown && typeof Files[0] != "string")
                          ? Files[0].file
                          : data,
                    },
                  ]}
                />
                <Button
                  variant="contained"
                  sx={{ my: "1rem", background: "var(--secondary-color)" }}
                  onClick={uploadFileAgreement}
                  fullWidth
                >
                  Upload
                </Button>
              </>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                height={"100%"}
                justifyContent={"center"}
                border={"1px solid #ddd"}
                borderRadius={"5px"}
              >
                <Typography
                  variant="h4"
                  fontWeight={700}
                  color={"text.secondary"}
                >
                  Please Upload File for Preview
                </Typography>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal> */}
    </Container>
  );
};

export default CheckoutRentalAgreement;
