import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TabsSorter from "../TabsSorter/TabsSorter";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  resetNotification,
} from "../StateSlices/notificationSlice";
import { sortDataPurchaseOrder, toastOption } from "../../utils/utils";
import ProductCard from "../ProductCard/ProductCard";
import {
  cancelOrder,
  changeOrderStatus,
  extendOrder,
  resetCancel,
} from "../StateSlices/orderCancelSlice";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import { LoadingButton } from "@mui/lab";
import { Close, Save } from "@mui/icons-material";
import swal from "sweetalert";
import "rsuite/dist/rsuite-no-reset.min.css";
import { DateRangePicker } from "rsuite";
import { FaArrowRotateLeft } from "react-icons/fa6";
import noitem from "../../assets/no-item.png";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const MyTransactions = () => {
  const [spinner, setSpinner] = useState(false);
  const [sortType, setSortType] = useState("newest");
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [orderExtension, setOrderExtension] = useState("");
  const [rentalExtReqModal, setRentalExtReqModal] = useState(false);
  const [contactUsReqModal, setContactUsReqModal] = useState(false);
  const [equipTypeFilter, setEquipTypeFilter] = useState([]);
  const [orderTypeFilter, setOrderTypeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [dateRangeFilter, setDateRangeFilter] = useState("");
  const rentalExtMsgRef = useRef();
  const contactUsRef = useRef();
  const [checkboxStatus, setCheckboxStatus] = useState([]);

  const dispatch = useDispatch();

  const { status, userInfo } = useSelector((state) => state.user);
  const { notificationMessage, notificationInfo } = useSelector(
    (state) => state.notification
  );
  const { cancelStatus, cancelMessage } = useSelector((state) => state.cancel);

  useEffect(() => {
    if (userInfo) {
      let token = localStorage.getItem("inktoken");
      dispatch(getNotification({ token, filter: "all" }));
      setSpinner(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (notificationInfo) {
      setData(notificationInfo);
      let filteredData = filterProducts(notificationInfo);
      console.log("noti", notificationInfo, filteredData);
      setFilterData(filteredData);
      dispatch(resetNotification());
      setSpinner(false);
    }
  }, [notificationInfo]);

  useEffect(() => {
    setSpinner(true);
    let filteredData = filterProducts(data);
    const sortedData = sortDataPurchaseOrders(filteredData, sortType, "both");
    if (sortedData) {
      // setData(sortedData);
      setFilterData(sortedData);
      setTimeout(() => {
        setSpinner(false);
      }, 2000);
    }
  }, [sortType]);

  const sortDataPurchaseOrders = (products, sort, type) => {
    if (products) {
      let sortedProducts = products;
      if (products.length > 1) {
        if (sort && sort === "recent") {
          const twoDaysAgo = new Date();
          twoDaysAgo.setDate(twoDaysAgo.getDate() - 5);
          sortedProducts = sortedProducts.filter(
            (p) => new Date(p.createdAt) >= twoDaysAgo
          );
        } else {
          sortedProducts = products.slice().sort((a, b) => {
            switch (sort) {
              case "newest":
                return new Date(b.createdAt) - new Date(a.createdAt);
              case "oldest":
                return new Date(a.createdAt) - new Date(b.createdAt);
              case "recent":
                const now = new Date();
                const twoDaysAgo = now.setDate(now.getDate() - 2);
                const isRecentA = new Date(a.createdAt) >= new Date(twoDaysAgo);
                const isRecentB = new Date(b.createdAt) >= new Date(twoDaysAgo);
                if (isRecentA && !isRecentB) return -1;
                if (!isRecentA && isRecentB) return 1;
                return 0;
              case "price":
                return a.pricePaid - b.pricePaid;
              case "price-desc":
                return b.pricePaid - a.pricePaid;
              default:
                return 0; // No sorting
            }
          });
        }
      }
      return sortedProducts;
    }
  };

  const handleOrderStatus = (status, id, productId) => {
    let token = localStorage.getItem("inktoken");
    let userStatus;
    let orderDetail = data.find((dt) => dt.order._id == id);
    console.log("ORDERSTATUSCHANGE", orderDetail, id, data);
    if (orderDetail && orderDetail.order.userId == userInfo._id) {
      userStatus = "buyer";
    } else {
      userStatus = "seller";
    }
    dispatch(
      changeOrderStatus({
        token,
        checkoutData: { id, productId, status, user: userStatus },
      })
    );
    setData([]);
    setFilterData([]);
    setSpinner(true);
  };

  useEffect(() => {
    if (cancelMessage == "Order Status Change") {
      toast.success("Order Status Updated successfully!", toastOption);
      dispatch(resetCancel());
      let token = localStorage.getItem("inktoken");
      dispatch(getNotification({ token, filter: "all" }));
    } else if (cancelMessage == "Order Extend") {
      toast.success("Order Extend Request Sent Successfully!", toastOption);
      dispatch(resetCancel());
      setRentalExtReqModal(false);
      rentalExtMsgRef.current.value = "";
      setOrderExtension("");
      setSpinner(false);
    } else if (cancelMessage == "Support Extend") {
      toast.success(
        "Order Support Request Extended Successfully!",
        toastOption
      );
      dispatch(resetCancel());
      setRentalExtReqModal(false);
      setContactUsReqModal(false);
      contactUsRef.current.value = "";
      setOrderExtension("");
      setSpinner(false);
    } else if (cancelMessage) {
      dispatch(
        getNotification({
          token: localStorage.getItem("inktoken"),
          filter: "all",
        })
      );
      toast.success("Order cancelled successfully!", toastOption);
      dispatch(resetCancel());
      // setSpinner(false);
    }
  }, [cancelMessage]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRentalExtRequest = () => {
    if (!rentalExtMsgRef.current.value) {
      toast.error("Please write the Reason for Extension", toastOption);
      return false;
    }
    let token = localStorage.getItem("inktoken");
    dispatch(
      extendOrder({
        extendData: {
          orderId: orderExtension,
          message: rentalExtMsgRef.current.value,
        },
        token,
      })
    );
  };

  const handleContactUs = () => {
    if (!contactUsRef.current.value) {
      toast.error("Please write the Support Reason", toastOption);
      return false;
    }
    let token = localStorage.getItem("inktoken");
    dispatch(
      extendOrder({
        extendData: {
          orderId: "support",
          message: contactUsRef.current.value,
        },
        token,
      })
    );
  };

  const handleContactUsMessage = () => {
    if (!contactUsRef.current.value) {
      toast.error("Please write a message to send", toastOption);
      return false;
    }
    let token = localStorage.getItem("inktoken");
    // dispatch(
    //   extendOrder({
    //     extendData: {
    //       orderId: orderExtension,
    //       message: rentalExtMsgRef.current.value,
    //     },
    //     token,
    //   })
    // );
  };

  const isLessThan72Hours = (endDateStr) => {
    const rentalEndDate = new Date(endDateStr);
    const now = new Date();

    const timeDifference = rentalEndDate - now;
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    console.log(hoursDifference);
    return hoursDifference > 72;
  };

  const handleCancelDispatch = (orderId, productId) => {
    swal({
      title: "Cancel Order?",
      text: "Once Cancelled, Order cannot be reactivated",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("inktoken");
        if (token) {
          dispatch(
            cancelOrder({ token, checkoutData: { orderId, productId } })
          );
          setSpinner(true);
        }
      }
    });
  };
  const { beforeToday } = DateRangePicker;

  const filterProducts = (orderData) => {
    let filteredData = orderData;

    let equipTypeFilter = [];
    let orderTypeFilter = [];

    if (checkboxStatus.length > 0) {
      checkboxStatus.forEach((type) => {
        if (type === "bought") {
          if (!orderTypeFilter.includes("buy")) orderTypeFilter.push("buy");
          if (!equipTypeFilter.includes("bought"))
            equipTypeFilter.push("bought");
        } else if (type === "rentedByMe") {
          if (!orderTypeFilter.includes("rent")) orderTypeFilter.push("rent");
          if (!equipTypeFilter.includes("rentedByMe"))
            equipTypeFilter.push("rentedByMe");
        } else if (type === "sold") {
          if (!orderTypeFilter.includes("sold")) orderTypeFilter.push("sold");
          if (!equipTypeFilter.includes("sold")) equipTypeFilter.push("sold");
        } else if (type === "rentedToOthers") {
          if (!orderTypeFilter.includes("rent")) orderTypeFilter.push("rent");
          if (!equipTypeFilter.includes("rentedToOthers"))
            equipTypeFilter.push("rentedToOthers");
        }
      });
    }

    if (equipTypeFilter.length > 0) {
      filteredData = filteredData.filter((dt) => {
        const product = dt.order.products.find(
          (pd) => pd.productId === dt.productId
        );

        if (product) {
          if (
            equipTypeFilter.includes("bought") &&
            product.type === "buy" &&
            dt.order.userId === userInfo._id
          ) {
            return true;
          }
          if (
            equipTypeFilter.includes("rentedByMe") &&
            product.type === "rent" &&
            dt.order.userId === userInfo._id
          ) {
            return true;
          }
          if (
            equipTypeFilter.includes("sold") &&
            product.type === "buy" &&
            dt.order.userId !== userInfo._id
          ) {
            return true;
          }
          if (
            equipTypeFilter.includes("rentedToOthers") &&
            product.type === "rent" &&
            dt.order.userId !== userInfo._id
          ) {
            return true;
          }
        }
        return false;
      });
    }

    // if (orderTypeFilter.length > 0) {
    //   filteredData = filteredData.filter((dt) => {
    //     return orderTypeFilter.some((type) => {
    //       if (type === "buy" && dt.order.userId === userInfo._id) {
    //         return equipTypeFilter.includes("bought");
    //       }
    //       if (type === "rent" && dt.order.userId === userInfo._id) {
    //         return equipTypeFilter.includes("rentedByMe");
    //       }
    //       if (type === "sold" && dt.order.userId !== userInfo._id) {
    //         return equipTypeFilter.includes("sold");
    //       }
    //       if (type === "rent" && dt.order.userId !== userInfo._id) {
    //         return equipTypeFilter.includes("rentedToOthers");
    //       }
    //       return false;
    //     });
    //   });
    // }

    // if(checkboxStatus.length > 0){
    //   checkboxStatus.map((type) => {
    //     if(type === "bought"){
    //       if(!orderTypeFilter.includes("buy")) orderTypeFilter.push("buy")
    //       if(!equipTypeFilter.includes("buy")) equipTypeFilter.push("buy")
    //     }else if(type === "rentedByMe"){
    //       if(!orderTypeFilter.includes("buy")) orderTypeFilter.push("buy")
    //       if(!equipTypeFilter.includes("rent")) equipTypeFilter.push("rent")
    //     }else if(type === "sold"){
    //       if(!orderTypeFilter.includes("sold")) orderTypeFilter.push("sold")
    //       if(!equipTypeFilter.includes("buy")) equipTypeFilter.push("buy")
    //     }else if(type === "rentedToOthers"){
    //       if(!orderTypeFilter.includes("sold")) orderTypeFilter.push("sold")
    //       if(!equipTypeFilter.includes("rent")) equipTypeFilter.push("rent")
    //     }
    //   })
    // }

    // if(equipTypeFilter.length === 1){
    //   filteredData = filteredData.filter((dt) => {
    //     if (equipTypeFilter.includes("buy")) {
    //       return dt.order.products.find(
    //         (pd) => pd.productId === dt.productId
    //       ).type === "buy";
    //     } else {
    //       return dt.order.products.find(
    //         (pd) => pd.productId === dt.productId
    //       ).type === "rent";
    //     }
    //   });
    // }

    // if(orderTypeFilter.length === 1){
    //   filteredData = filteredData.filter((dt) => {
    //     if (orderTypeFilter.includes("buy")) {
    //       return dt.order.userId == userInfo._id
    //     } else {
    //       return dt.order.userId != userInfo._id;
    //     }
    //   });
    // }

    if (statusFilter) {
      filteredData = filteredData.filter((dt) => {
        if (statusFilter === "pending") {
          return dt.order.userId == userInfo._id
            ? dt.order.products.find((pd) => pd.productId === dt.productId)
                .buyerStatus
              ? dt.order.products.find((pd) => pd.productId === dt.productId)
                  .buyerStatus == "pending"
              : true
            : dt.order.products.find((pd) => pd.productId === dt.productId)
                .sellerStatus
            ? dt.order.products.find((pd) => pd.productId === dt.productId)
                .sellerStatus == "pending"
            : true;
        } else if (statusFilter === "complete") {
          return dt.order.userId == userInfo._id
            ? dt.order.products.find((pd) => pd.productId === dt.productId)
                .buyerStatus == "complete"
            : dt.order.products.find((pd) => pd.productId === dt.productId)
                .sellerStatus == "complete";
        } else {
          return dt.order.userId == userInfo._id
            ? dt.order.products.find((pd) => pd.productId === dt.productId)
                .buyerStatus == "incomplete"
            : dt.order.products.find((pd) => pd.productId === dt.productId)
                .sellerStatus == "incomplete";
        }
      });
    }

    if (dateRangeFilter.length > 0) {
      filteredData = filteredData.filter((dt) => {
        let rentalDateRange = dt.order.products.find(
          (pd) => pd.productId === dt.productId
        ).rentalDateRange;

        if (rentalDateRange.length > 0) {
          let startDate = new Date(rentalDateRange[0]);
          let endDate = new Date(rentalDateRange[1]);
          let dateRange = new Date(dateRangeFilter[0]);
          let dateRangeEnd = new Date(dateRangeFilter[1]);
          if (startDate >= dateRange && endDate <= dateRangeEnd) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    }

    return filteredData;
  };

  useEffect(() => {
    if (
      equipTypeFilter ||
      orderTypeFilter ||
      statusFilter ||
      dateRangeFilter ||
      checkboxStatus.length > 0
    ) {
      setSpinner(true);
      let filteredData = filterProducts(data);
      setFilterData(filteredData);
      setTimeout(() => {
        setSpinner(false);
      }, 1000);
    }
  }, [
    equipTypeFilter,
    orderTypeFilter,
    statusFilter,
    dateRangeFilter,
    checkboxStatus,
  ]);

  const resetFilter = () => {
    setEquipTypeFilter("");
    setOrderTypeFilter("");
    setStatusFilter("");
    setDateRangeFilter([]);
    setCheckboxStatus([]);
  };

  return (
    <>
      <Box component={"div"} marginTop={"2rem"}>
        <Box component={"div"} className="tabs-header">
          <Typography variant="h2" className="title mont-title">
            My Transactions
          </Typography>
          <Stack direction={"row"}>
            {(equipTypeFilter ||
              orderTypeFilter ||
              statusFilter ||
              dateRangeFilter ||
              checkboxStatus.length > 0) && (
              <Typography
                display={"flex"}
                alignItems={"center"}
                sx={{ cursor: "pointer" }}
                variant="body2"
                onClick={resetFilter}
              >
                <span style={{ marginRight: ".5rem" }}>Reset</span>{" "}
                <FaArrowRotateLeft />
              </Typography>
            )}

            <TabsSorter setSortType={setSortType} />
            <Button
              size="small"
              onClick={() => setContactUsReqModal(true)}
              //   href="mailto:support@godizel.com"
              variant="contained"
              color="secondary"
            >
              Contact Us
            </Button>
          </Stack>
        </Box>

        <Stack
          margin={"1rem 0"}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={"1rem"}
        >
          {/* <Typography
            variant="h4"
            fontWeight={700}
            className="mont-title"
            marginRight={"1rem"}
          >
            Filters:{" "}
          </Typography> */}

          {/* <FormControl size="small" margin="0 .5rem" sx={{ minWidth: "120px" }}>
            <InputLabel id="equipment-type-filter-label" size="small">
              Type
            </InputLabel>
            <Select
              labelId="equipment-type-filter-label"
              id="equipment-type-filter"
              value={equipTypeFilter}
              label="Type"
              onChange={(e) => setEquipTypeFilter(e.target.value)}
            >
              <MenuItem value={"buy"}>Sale</MenuItem>
              <MenuItem value={"rent"}>Rent</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" margin="0 .5rem" sx={{ minWidth: "120px" }}>
            <InputLabel id="order-type-filter-label" size="small">
              Event
            </InputLabel>
            <Select
              labelId="order-type-filter-label"
              id="order-type-filter"
              value={orderTypeFilter}
              label="Event"
              onChange={(e) => setOrderTypeFilter(e.target.value)}
            >
              <MenuItem value={"buy"}>Purchased</MenuItem>
              <MenuItem value={"sold"}>Sold</MenuItem>
            </Select>
          </FormControl> */}
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={"1rem"}
          >
            <FormControl
              component="fieldset"
              sx={{ margin: "0 .5rem", minWidth: "120px" }}
            >
              <FormGroup
                sx={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "row",
                  flex: "2",
                }}
              >
                <FormControlLabel
                  sx={{ flexBasis: "calc(23% - 0.5rem)" }}
                  control={
                    <Checkbox
                      checked={checkboxStatus.includes("bought")}
                      onChange={(e) => {
                        const newFilter = e.target.checked
                          ? [...checkboxStatus, "bought"]
                          : checkboxStatus.filter((type) => type !== "bought");
                        // const newFilter = e.target.checked
                        //   ? [...orderTypeFilter, "buy"]
                        //   : orderTypeFilter.filter((type) => type !== "buy");
                        //   setOrderTypeFilter(newFilter);
                        // const newTypeFilter = e.target.checked
                        //   ? [...equipTypeFilter, "buy"]
                        //   : equipTypeFilter.filter((type) => type !== "buy");
                        //   setEquipTypeFilter(newTypeFilter)
                        setCheckboxStatus(newFilter);
                      }}
                      name="bought"
                    />
                  }
                  label="Equipment bought by me"
                />
                <FormControlLabel
                  sx={{ flexBasis: "calc(23% - 0.5rem)" }}
                  control={
                    <Checkbox
                      checked={checkboxStatus.includes("rentedByMe")}
                      onChange={(e) => {
                        const newFilter = e.target.checked
                          ? [...checkboxStatus, "rentedByMe"]
                          : checkboxStatus.filter(
                              (type) => type !== "rentedByMe"
                            );
                        setCheckboxStatus(newFilter);
                        // const newFilter = e.target.checked
                        //   ? [...orderTypeFilter, "buy"]
                        //   : orderTypeFilter.filter((type) => type !== "buy");
                        //   setOrderTypeFilter(newFilter);
                        // const newTypeFilter = e.target.checked
                        //   ? [...equipTypeFilter, "rent"]
                        //   : equipTypeFilter.filter((type) => type !== "rent");
                        //   setEquipTypeFilter(newTypeFilter)
                      }}
                      name="rentedByMe"
                    />
                  }
                  label="Equipment rented by me"
                />
                <FormControlLabel
                  sx={{ flexBasis: "calc(23% - 0.5rem)" }}
                  control={
                    <Checkbox
                      checked={checkboxStatus.includes("sold")}
                      onChange={(e) => {
                        const newFilter = e.target.checked
                          ? [...checkboxStatus, "sold"]
                          : checkboxStatus.filter((type) => type !== "sold");
                        setCheckboxStatus(newFilter);
                        // const newFilter = e.target.checked
                        // ? [...orderTypeFilter, "sold"]
                        // : orderTypeFilter.filter((type) => type !== "sold");
                        // setOrderTypeFilter(newFilter);
                        // const newTypeFilter = e.target.checked
                        //   ? [...equipTypeFilter, "buy"]
                        //   : equipTypeFilter.filter((type) => type !== "buy");
                        //   setEquipTypeFilter(newTypeFilter)
                      }}
                      name="sold"
                    />
                  }
                  label="Equipment sold to others"
                />
                <FormControlLabel
                  sx={{ flexBasis: "calc(23% - 0.5rem)" }}
                  control={
                    <Checkbox
                      checked={checkboxStatus.includes("rentedToOthers")}
                      onChange={(e) => {
                        const newFilter = e.target.checked
                          ? [...checkboxStatus, "rentedToOthers"]
                          : checkboxStatus.filter(
                              (type) => type !== "rentedToOthers"
                            );
                        setCheckboxStatus(newFilter);
                        //   const newFilter = e.target.checked
                        //   ? [...orderTypeFilter, "sold"]
                        //   : orderTypeFilter.filter((type) => type !== "sold");
                        //   setOrderTypeFilter(newFilter);
                        // const newTypeFilter = e.target.checked
                        //   ? [...equipTypeFilter, "rent"]
                        //   : equipTypeFilter.filter((type) => type !== "rent");
                        //   setEquipTypeFilter(newTypeFilter)
                      }}
                      name="rentedToOthers"
                    />
                  }
                  label="Equipment rented to others"
                />
              </FormGroup>
            </FormControl>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={"1rem"}
            width={"100%"}
          >
            <FormControl
              size="small"
              margin="0 .5rem"
              sx={{ minWidth: "150px" }}
            >
              <InputLabel id="status-type-filter-label" size="small">
                Status
              </InputLabel>
              <Select
                labelId="status-type-filter-label"
                id="status-type-filter"
                value={statusFilter}
                label="Status"
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"complete"}>Complete</MenuItem>
                <MenuItem value={"incomplete"}>Incomplete</MenuItem>
              </Select>
            </FormControl>
            <DateRangePicker
              style={{
                width: "100%",
                lineHeight: 2,
                borderColor: "var(--grey-color)",
              }}
              placeholder="Select Date Range : From ~ To"
              disabledDate={beforeToday()}
              // onOk={(newValue) => {
              //   setValue(newValue);
              //   setFieldValue("itemLease", [
              //     ...values.itemLease,
              //     {
              //       from: newValue[0],
              //       to: newValue[1],
              //     },
              //   ]);
              // }}
              onOk={(newValue) => setDateRangeFilter(newValue)}
              onClean={(val) => {
                setDateRangeFilter([]);
              }}
              // value={field.initVal}
              // value={value}
            />
          </Stack>
        </Stack>
        <Divider />
        {spinner ? (
          <Spinner height={"60vh"} />
        ) : data.length == 0 ? (
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            minHeight={"60vh"}
            width={"100%"}
            sx={{
              background: `url(${noitem})`,
              backgroundSize: "cover",
            }}
          >
            <Typography color={"var(--black-color)"} variant="h3">
              No orders found!
            </Typography>
          </Stack>
        ) : (
          <>
            <Box
              component={"div"}
              margin={"1rem 0"}
              className="purchases-item-con"
            >
              {filterData.length > 0 &&
                filterData.map((dt, ind) => (
                  <Box
                    component={"div"}
                    marginTop={".5rem"}
                    marginBottom={"2rem"}
                  >
                    <Stack
                      direction={"row"}
                      columnGap={4}
                      justifyContent={
                        windowWidth < 820 ? "center" : "flex-start"
                      }
                      alignItems={"center"}
                      className="order-purchase-wrap-con"
                    >
                      <Box maxWidth={"400px"}>
                        <ProductCard
                          hideLikeIcon={true}
                          shopPageCardWidth={
                            windowWidth < 1080 && windowWidth > 820
                              ? null
                              : windowWidth < 430
                              ? null
                              : "400px"
                          }
                          product={dt.product}
                          userInfo={userInfo}
                          purchase={dt}
                          buttons={
                            dt.eventName === "ordered"
                              ? ["View Details", "Cancel Order"]
                              : ["View Details"]
                          }
                          handleCancelDispatch={handleCancelDispatch}
                        />
                        <Stack
                          direction={"row"}
                          flex={"50%"}
                          justifyContent={"space-evenly"}
                          alignItems={"center"}
                          sx={{ marginTop: "1rem" }}
                        >
                          {/* <Typography variant="h5" marginRight={"1rem"}>
                            <strong>Status: </strong>
                          </Typography> */}
                          <FormControl
                            // variant="standard"
                            fullWidth
                            sx={{ padding: ".5rem" }}
                          >
                            <InputLabel
                              sx={{ padding: ".5rem" }}
                              id="orderStatusField"
                            >
                              Status
                            </InputLabel>
                            <Select
                              //   color="secondary"
                              labelId="orderStatusField"
                              id="demo-simple-select"
                              // value={orderStatus}
                              defaultValue={
                                dt.order.userId == userInfo._id
                                  ? dt.order.products.find(
                                      (pd) => pd.productId === dt.productId
                                    ).buyerStatus
                                    ? dt.order.products.find(
                                        (pd) => pd.productId === dt.productId
                                      ).buyerStatus
                                    : "pending"
                                  : dt.order.products.find(
                                      (pd) => pd.productId === dt.productId
                                    ).sellerStatus
                                  ? dt.order.products.find(
                                      (pd) => pd.productId === dt.productId
                                    ).sellerStatus
                                  : "pending"
                              }
                              label="Status"
                              // value={}
                              onChange={(e) => {
                                swal({
                                  title: "Update Status",
                                  text:
                                    "Are you sure you want to mark this as " +
                                    e.target.value +
                                    "?",
                                  icon: "warning",
                                  buttons: true,
                                  // dangerMode: true,
                                  // content: {
                                  //   element: "input",
                                  //   attributes: {
                                  //     placeholder: "Reason for Ban",
                                  //     type: "text",
                                  //     // require:true
                                  //   },
                                  // },
                                }).then((willReactive) => {
                                  if (willReactive) {
                                    handleOrderStatus(
                                      e.target.value,
                                      dt.order._id,
                                      dt.productId
                                    );
                                  } else {
                                  }
                                });
                              }}
                            >
                              <MenuItem color="secondary" value={"pending"}>
                                Pending
                              </MenuItem>
                              <MenuItem color="secondary" value={"complete"}>
                                Complete
                              </MenuItem>
                              <MenuItem color="secondary" value={"incomplete"}>
                                Incomplete
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Box>
                      <Box
                        sx={{
                          display: windowWidth < 850 && "none",
                          // display: { xs: "none", md: "block" },
                          borderRight: "2px solid var(--black-color)",
                          height: "480px",
                          width: "5px",
                        }}
                      >
                        &nbsp;
                      </Box>
                      <Box
                        width={"400px"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                      >
                        {/* <Typography
                          variant="h5"
                          my={1}
                          textTransform={"capitalize"}
                        >
                          <strong>Event Type:</strong> {dt.eventName}{" "}
                        </Typography> */}
                        <Typography variant="h5" my={1}>
                          <strong>
                            {dt.eventName === "ordered" ? "Purchase" : "Order"}{" "}
                            Id:
                          </strong>{" "}
                          {dt.orderId}
                        </Typography>
                        <Typography variant="h5" my={1}>
                          <strong>
                            {dt.eventName === "ordered" ? "Purchase" : "Order"}{" "}
                            Date:
                          </strong>{" "}
                          {new Date(dt.createdAt).toDateString()}
                        </Typography>
                        <br />
                        <Typography variant="h5" my={1}>
                          <strong>Amount {dt.eventName === "ordered" ? "Paid" : "Earned"}:</strong> $ {dt.pricePaid.toLocaleString("en-US") }
                        </Typography>
                        <Typography variant="h5" my={1}>
                          <strong>Rent Period:</strong>
                          <span style={{ textTransform: "capitalize" }}>
                            &nbsp;
                            {dt.order.products.find(
                              (pd) => pd.productId === dt.productId
                            ).rentalDateRange?.length > 0
                              ? new Date(
                                  dt.order.products.find(
                                    (pd) => pd.productId === dt.productId
                                  ).rentalDateRange[0]
                                ).toDateString() +
                                " - " +
                                new Date(
                                  dt.order.products.find(
                                    (pd) => pd.productId === dt.productId
                                  ).rentalDateRange[1]
                                ).toDateString()
                              : " - "}
                          </span>
                        </Typography>
                        <br />
                        <Typography variant="h5" my={1}>
                          <strong>
                            {dt.eventName === "ordered" ? "Purchase" : "Order"}{" "}
                            type:
                          </strong>
                          <span style={{ textTransform: "capitalize" }}>
                            &nbsp;
                            {dt.order.products.find(
                              (pd) => pd.productId === dt.productId
                            ).type
                              ? dt.order.products.find(
                                  (pd) => pd.productId === dt.productId
                                ).type
                              : "-"}
                          </span>
                        </Typography>
                        <Typography variant="h5" my={1}>
                          <strong>
                            {dt.eventName === "ordered" ? "Purchase" : "Order"}{" "}
                            Event:
                          </strong>
                          <span style={{ textTransform: "capitalize" }}>
                            &nbsp;
                            {dt.order.userId == userInfo._id
                              ? "Purchased"
                              : "Sold"}
                          </span>
                        </Typography>
                        <Typography variant="h5" my={1}>
                          <strong>
                            {dt.eventName === "ordered" ? "Seller" : "Buyer"}{" "}
                            Contact:
                          </strong>
                          <span>
                            &nbsp;
                            {dt.contact}
                          </span>
                        </Typography>
                        <br />
                        <br />
                        {/* <br /> */}
                        {/* <br /> */}
                        {dt.order.products.find(
                          (pd) => pd.productId === dt.productId
                        ).type &&
                        dt.order.products.find(
                          (pd) => pd.productId === dt.productId
                        ).type === "rent" &&
                        isLessThan72Hours(
                          dt.order.products.find(
                            (pd) => pd.productId === dt.productId
                          ).rentalDateRange[1]
                        ) ? (
                          <Button
                            onClick={() => {
                              setRentalExtReqModal(true);
                              setOrderExtension(dt.order._id);
                            }}
                            variant="contained"
                            sx={{
                              border: "1px solid var(--white-color)",
                              color: "var(--white-color)",
                              background: "var(--black-color)",
                              width: "100%",
                              "&:hover": {
                                border: "1px solid var(--grey-color)",
                                color: "var(--black-color)",
                                background: "var(--grey-color)",
                              },
                              marginTop: ".5rem",
                            }}
                          >
                            Request Rental Period Extension
                          </Button>
                        ) : (
                          <>
                            <br />
                            <br />
                          </>
                        )}
                        <a
                          href={`mailto:${dt.contact}`}
                          style={{ width: "100%" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            variant="contained"
                            sx={{
                              border: "1px solid var(--white-color)",
                              color: "var(--white-color)",
                              background: "var(--black-color)",
                              width: "100%",
                              "&:hover": {
                                border: "1px solid var(--grey-color)",
                                color: "var(--black-color)",
                                background: "var(--grey-color)",
                              },
                              marginTop: ".5rem",
                            }}
                          >
                            Contact{" "}
                            {dt.eventName === "ordered"
                              ? "Asset Owner"
                              : "Buyer"}
                          </Button>
                        </a>
                      </Box>
                    </Stack>
                  </Box>
                ))}
            </Box>
            <Box
              margin={"auto"}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {/* <Pagination
              count={Math.ceil(products.length / 10)}
              // variant="outlined"
              shape="rounded"
              // variant="text"
              page={page}
              onChange={handlePageChange}
              color="primary"
              sx={{
                padding: "1rem",
                borderRadius: "5px",
                border: "1px solid var(--primary-color)",
                marginTop: "1rem",
              }}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
            /> */}
            </Box>
          </>
        )}
      </Box>
      <Modal
        aria-labelledby="rental-extension-request-modal-title"
        aria-describedby="rental-extension-request-modal-description"
        open={rentalExtReqModal}
        onClose={() => {
          setRentalExtReqModal(false);
          rentalExtMsgRef.current.value = "";
          setOrderExtension("");
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 200,
          },
        }}
      >
        <Fade in={rentalExtReqModal}>
          <Box sx={modalStyle} position={"relative"}>
            <Typography
              id="rental-extension-request-modal-title"
              variant="h3"
              className="mont-title"
              marginLeft={0}
            >
              Rental Period Extension Request
            </Typography>
            <Box
              sx={{
                marginTop: "1rem",
                width: 50,
                height: 5,
                background: "var(--secondary-color)",
              }}
            >
              &nbsp;
            </Box>

            <Close
              onClick={() => {
                setRentalExtReqModal(false);
                rentalExtMsgRef.current.value = "";
                setOrderExtension("");
              }}
              sx={{
                position: "absolute",
                right: "2rem",
                top: "2rem",
                cursor: "pointer",
              }}
            />
            <Grid container>
              <Input
                inputRef={rentalExtMsgRef}
                multiline
                rows={5}
                fullWidth
                sx={{ marginTop: "1rem" }}
                type="text"
                color="secondary"
                placeholder=" Enter your message for rental period extension"
                // helperText="E.g. 2 / 5 / 10"
              />

              {cancelStatus === "loading" ? (
                <LoadingButton
                  fullWidth
                  sx={{ marginTop: "1rem" }}
                  loading
                  loadingPosition="end"
                  endIcon={<Save />}
                  variant="outlined"
                  disabled={true}
                >
                  <span>Please Wait</span>
                </LoadingButton>
              ) : (
                <Button
                  fullWidth
                  onClick={handleRentalExtRequest}
                  sx={{ marginTop: "1rem" }}
                  color="secondary"
                  variant="contained"
                >
                  Request
                </Button>
              )}
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="contact-us-extension-request-modal-title"
        aria-describedby="contact-us-extension-request-modal-description"
        open={contactUsReqModal}
        onClose={() => {
          setContactUsReqModal(false);
          contactUsRef.current.value = "";
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 200,
          },
        }}
      >
        <Fade in={contactUsReqModal}>
          <Box sx={modalStyle} position={"relative"}>
            <Typography
              id="contact-us-extension-request-modal-title"
              variant="h3"
              className="mont-title"
              marginLeft={0}
            >
              Write a message to our support team
            </Typography>
            <Box
              sx={{
                marginTop: "1rem",
                width: 50,
                height: 5,
                background: "var(--secondary-color)",
              }}
            >
              &nbsp;
            </Box>

            <Close
              onClick={() => {
                setContactUsReqModal(false);
                contactUsRef.current.value = "";
                setOrderExtension("");
              }}
              sx={{
                position: "absolute",
                right: "2rem",
                top: "2rem",
                cursor: "pointer",
              }}
            />
            <Grid container>
              <Input
                inputRef={contactUsRef}
                multiline
                rows={5}
                fullWidth
                sx={{ marginTop: "1rem" }}
                type="text"
                color="secondary"
                placeholder=" Enter your message for Contacting Support Team"
                // helperText="E.g. 2 / 5 / 10"
              />

              {cancelStatus === "loading" ? (
                <LoadingButton
                  fullWidth
                  sx={{ marginTop: "1rem" }}
                  loading
                  loadingPosition="end"
                  endIcon={<Save />}
                  variant="outlined"
                  disabled={true}
                >
                  <span>Please Wait</span>
                </LoadingButton>
              ) : (
                <Button
                  fullWidth
                  onClick={handleContactUs}
                  sx={{ marginTop: "1rem" }}
                  color="secondary"
                  variant="contained"
                >
                  Send
                </Button>
              )}
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default MyTransactions;
